'use strict';
import PropTypes from 'prop-types';
import ArrowLeftIcon from 'dibs-icons/exports/legacy/ArrowLeft';
import ArrowRightIcon from 'dibs-icons/exports/legacy/ArrowRight';
import { FormattedMessage } from 'dibs-react-intl';

import PaginationItem from './PaginationItem';

import styles from './Pagination.scss';

const getSeparator = key => (
    <div
        key={`page${key}`}
        className={`${styles.separator} ${styles.itemWrapper}`}
        data-tn="separator-wrapper"
    >
        <div className={styles.item} data-tn="separator">
            ...
        </div>
    </div>
);

const getSelectedItem = page => (
    <div
        key={`page${page}`}
        className={`${styles.selected} ${styles.itemWrapper}`}
        data-tn="active-page-wrapper"
    >
        <div className={styles.item} data-tn="active-page">
            {page}
        </div>
    </div>
);

const getLinkItem = (page, props) => {
    const { getPaginationLink } = props;
    const href = getPaginationLink ? getPaginationLink(page, props) : '';

    return (
        <PaginationItem
            key={`page${page}`}
            nextPage={page}
            href={href}
            className={`${styles.link} ${styles.itemWrapper}`}
            numberOfPages={props.numberOfPages}
            dataTn={`page-${page}`}
            onPageChange={props.onPageChange}
        >
            <div className={styles.item}>{page}</div>
        </PaginationItem>
    );
};

getLinkItem.propTypes = {
    numberOfPages: PropTypes.number,
    onPageChange: PropTypes.func,
    getPaginationLink: PropTypes.func,
};

const getNextButton = props => {
    const { page, numberOfPages, getPaginationLink } = props;
    const next = page + 1;
    const href = getPaginationLink ? getPaginationLink(next, props) : '';

    return (
        <PaginationItem
            key={`nextpage${next}`}
            nextPage={next}
            href={href}
            className={`${styles.button} ${styles.nextButton}`}
            numberOfPages={numberOfPages}
            dataTn="nextButton"
            onPageChange={props.onPageChange}
        >
            <FormattedMessage id="Pagination.next" defaultMessage="next" />
            <span className={styles.svgArrowWrapper}>
                <ArrowRightIcon />
            </span>
        </PaginationItem>
    );
};

getNextButton.propTypes = {
    page: PropTypes.number,
    numberOfPages: PropTypes.number,
    onPageChange: PropTypes.func,
    getPaginationLink: PropTypes.func,
};

const getPreviousButton = props => {
    const { page, numberOfPages, getPaginationLink } = props;
    const previous = page - 1;
    const href = getPaginationLink ? getPaginationLink(previous, props) : '';

    return (
        <PaginationItem
            key={`previouspage${previous}`}
            nextPage={previous}
            href={href}
            className={`${styles.button} ${styles.previousButton}`}
            numberOfPages={numberOfPages}
            dataTn="previousButton"
            onPageChange={props.onPageChange}
        >
            <span className={styles.svgArrowWrapper}>
                <ArrowLeftIcon />
            </span>
            <FormattedMessage id="Pagination.previous" defaultMessage="previous" />
        </PaginationItem>
    );
};

getPreviousButton.propTypes = {
    page: PropTypes.number,
    numberOfPages: PropTypes.number,
    getPaginationLink: PropTypes.func,
    onPageChange: PropTypes.func,
};

const isFullLine = (rightSide, leftSide, lenght) =>
    rightSide.length + leftSide.length + 1 === lenght;

const getItems = props => {
    const { page, maxLength, numberOfPages } = props;
    let length = maxLength || 9;
    const leftSide = [];
    const rightSide = [];
    let items = [];
    let pageLeft = page - 1;
    let pageRight = page + 1;
    let addRightSeparator = false;
    let addLeftSeparator = false;

    if (numberOfPages > maxLength) {
        if (page > 4) {
            length = length - 2;
            addLeftSeparator = true;
        }

        if (numberOfPages - page > 3) {
            length = length - 2;
            addRightSeparator = true;
        }
    }

    if (length > 1) {
        for (let i = 1; i <= length; i++) {
            if (pageRight <= numberOfPages) {
                rightSide.push(getLinkItem(pageRight, props));
                pageRight++;
            }

            if (isFullLine(rightSide, leftSide, length)) {
                break;
            }

            if (pageLeft > 0) {
                leftSide.push(getLinkItem(pageLeft, props));
                pageLeft--;
            }

            if (isFullLine(rightSide, leftSide, length)) {
                break;
            }
        }
    }

    if (addLeftSeparator) {
        leftSide.push(getSeparator(2));
        leftSide.push(getLinkItem(1, props));
    }

    leftSide.reverse();
    leftSide.push(getSelectedItem(page));
    items = leftSide.concat(rightSide);

    if (addRightSeparator) {
        items.push(getSeparator(numberOfPages - 1));
        items.push(getLinkItem(numberOfPages, props));
    }
    return items.length > 1 ? items : null;
};

const Pagination = props => {
    const { page = 1, numberOfPages = 1 } = props;

    return (
        <div className={styles.container}>
            <div className={styles.pages}>
                {page > 1 && getPreviousButton(props)}
                {getItems(props)}
                {page !== numberOfPages && getNextButton(props)}
            </div>
        </div>
    );
};

Pagination.propTypes = {
    page: PropTypes.number,
    maxLength: PropTypes.number,
    numberOfPages: PropTypes.number,
    getPaginationLink: PropTypes.func,
    onPageChange: PropTypes.func,
};

export default Pagination;
